(
    function (global, Si) {
        "use strict";

        Si.when(['Ajax']).then(profileAction);

        function profileAction(Ajax) {
            var profileAction = Ajax.action('profile', {});

            Si.component('Ajax/Profile', profileAction);
        }
    }
)(window, Si);