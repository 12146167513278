(function (global, $, jcf, Si) {
    "use strict";

	function scrollToAlert() {
		var top;

		if ($('.login-form').length) {
			top = $('.login-form').offset().top;
		}
		$(window).scrollTop(top);
	}
	setTimeout(function() {
		scrollToAlert();
	}, 300);

})(window, jQuery, jcf, Si);
