(function (global, $, Si) {
    "use strict";

    Si.when('Templates').then(profileModalComponent);

    function profileModalComponent(Templates) {

        Si.component('ProfileModal', {
            initProfileModal: initProfileModal
        });

        var params = {
                mr: '',
                pmr: '',
                country: '',
                name: '',
                email: '',
                id: '',
            },
            VIEW_MODAL = 'profile_modal',
            $loader = $('[data-loader]');


        function initProfileModal(modalSelector, modalLinkSelector) {

            $(modalLinkSelector).on('click', profileClickHandler);

            function profileClickHandler(e) {
                e.preventDefault();
                $(modalSelector).remove();
                $loader.addClass('visible');
                fillLoadParams(e.target, 0);
                loadData();
            }

            function fillLoadParams(element) {
                params.country = $(element).data('country');
                params.name = $(element).data('name');
                params.mr = $(element).data('mr');
                params.pmr = $(element).data('pmr');
                params.email = $(element).data('email');
                params.id = $(element).data('id');
            }

            function renderView(view, response) {
                var content = Templates.make('profile/' + view + '.mustache', response);
                $('body').append(content);
                $loader.removeClass('visible');
                $(modalSelector).modal('show');
            }


            function loadData() {
                renderView(VIEW_MODAL, params);
                $loader.removeClass('visible');
            }
        }
    }
})(window, jQuery, Si);